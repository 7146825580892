import React from "react"
import { Link, graphql } from "gatsby"

import parse from "html-react-parser"

// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/UI/Layout"
import Seo from "../components/UI/Seo"


type PageTemplateProps = {
  data: GatsbyTypes.PageByIdQuery
}

const PageTemplate = ({ data }: PageTemplateProps) => {
  const { page } = data
  return (
    <Layout pageTitle={page?.title!} isHomePage={page?.uri === "/" ?? false}>
      <Seo title={page?.title} meta={[
        { name: `description`, content: page?.seo?.metaDesc || `` },
        { name: `keywords`, content: page?.seo?.metaKeywords || `` },
        { property: `og:title`, content: page?.seo?.opengraphTitle || page?.title! },
        { property: `og:description`, content: page?.seo?.opengraphDescription! },
        { property: `og:type`, content: `website` },
        { property: `og:author`, content: page?.seo?.opengraphAuthor || `Neha Kale` },
        { property: `og:image`, content: page?.seo?.opengraphImage?.sourceUrl || `` },
        { property: `og:image:alt`, content: page?.seo?.opengraphImage?.altText || `` },
        { property: `og:url`, content: page?.seo?.canonical || `` },
        { name: `twitter:card`, content: `summary` },
        { name: `twitter:title`, content: page?.seo?.twitterTitle || page?.title! },
        { name: `twitter:description`, content: page?.seo?.twitterDescription! },
        { name: `twitter:image`, content: page?.seo?.twitterImage?.sourceUrl! },
      ]} />
      <article
        className=""
        itemScope
        itemType="http://schema.org/Article"
      >
        {!!page?.content && parse(page?.content!)}
      </article>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageById($id: String!) {
    page: wpPage(id: {eq: $id}) {
      title
      content
      uri
      seo {
        twitterTitle
        twitterImage {
          altText
          sourceUrl
        }
        twitterDescription
        title
        readingTime
        opengraphUrl
        opengraphType
        opengraphTitle
        opengraphSiteName
        opengraphPublisher
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphImage {
          altText
          sourceUrl
        }
        opengraphDescription
        opengraphAuthor
        metaDesc
        metaKeywords
        focuskw
        cornerstone
        canonical
      }
    }
  }
`